<template>
  <v-container fluid>
    <v-row dense>
      <v-col lg="10" offset-lg="1">
        <v-card-title>
          <v-btn to="/sales-orders" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Edit Sales Order</h3>
          <v-spacer></v-spacer>
          <!--Generate PDF Button -->
          <v-menu
            bottom
            open-on-hover
            :offset-y="offset"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="2"
                color="#e7e7f7"
                light
                class="ml-10"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-file-pdf</v-icon>
                Generate PDF
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list
              v-for="doc in [
                { name: 'Quotation' },
                { name: 'Pro-Forma Invoice' },
              ]"
              :key="doc.name"
            >
              <!--Sales order PDF button-->
              <v-list-item link @click="generateSalesPDF(doc.name)">
                <v-icon left>mdi-file-pdf</v-icon>
                <v-list-item-content>{{ doc.name }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--End of generate Button-->
        </v-card-title>
        <v-card>
          <!--New Sales Order form -->
          <v-form
            @submit.prevent="updateSalesOrder"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <!-- Branch -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                      v-model="order_branch"
                      :items="branches"
                      :rules="[RequireFormInput('branch')]"
                      item-text="branch_name"
                      return-object
                      cache-items
                      label="Branch"
                      outlined
                      dense
                      color="#3d2cdd"
                      class="font-weight-bold"
                      :menu-props="{ closeOnClick: true }"
                      required
                  />
                </v-col>
              </v-row>
              <!--Job details-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Job Details</h4>
                </v-col>
              </v-row>
              <v-row>
                <!--Quote Number-->
                <QuoteNumberInput
                  v-if="
                    order_status === 'Quote' ||
                    (order_status === 'Confirmed' && !quote_ref) ||
                    (order_status === 'Completed' && !quote_ref)
                  "
                  :value="order_number"
                  :loading="loading"
                />
                <!--Order Number-->
                <OrderNumberInput
                  v-else-if="
                    (order_status === 'Confirmed' && !!quote_ref) ||
                    (order_status === 'Completed' && !!quote_ref)
                  "
                  :value="order_number"
                  :quote_ref="quote_ref"
                  :loading="loading"
                />
                <!--Date Picker-->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="order_date"
                        label="Date"
                        append-icon="mdi-calendar"
                        :rules="[RequireFormInput('date')]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                        color="#3d2cdd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="order_date"
                      @change="setVATRate()"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                      :allowed-dates="
                        (date) => date <= new Date().toISOString().substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <!--Customers-->
                <v-col cols="12" sm="6" md="6">
                  <!--populate with customer names from database-->
                  <v-autocomplete
                    v-model="customer"
                    :rules="[RequireFormInput('customer')]"
                    :items="customers"
                    item-text="customer_name"
                    return-object
                    cache-items
                    label="Customer"
                    outlined
                    dense
                    color="#3d2cdd"
                    class="font-weight-bold"
                    :loading="loading_customer"
                  >
                    <template v-slot:selection="data" trim>
                      {{ data.item.customer_name }}
                    </template>
                    <!--Dropdown items-->
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.customer_name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.customer_email"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <!--A button that lets users add new customers if not in select list
                                    Reroute to Add Customer interface-->
                    <template>
                      <v-list-item slot="append-item">
                        <v-btn text color="#3d2cdd" to="/new-customer"
                          ><v-icon>mdi-plus</v-icon> Add New Customer</v-btn
                        >
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--Order Status-->
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="order_status"
                    required
                    :items="order_status_items"
                    @change="updateOrderNum()"
                    label="Order Status"
                    color="#3d2cdd"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <!--Discount and delivery-->
              <v-row>
                <v-col sm="6" md="6">
                  <v-text-field
                    v-model.number="discountRate"
                    prefix="%"
                    min="0"
                    max="100"
                    step="0.01"
                    type="number"
                    label="Discount"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="6" md="6">
                  <v-text-field
                    v-model.number="order_delivery_fee"
                    prefix="R"
                    min="0"
                    step="0.01"
                    type="number"
                    label="Delivery Fee"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Payments List Feature-->
              <span v-if="order_status !== 'Quote'">
                  <PaymentList
                    v-if="order_date >= featureFlags.paymentListFeature"
                    :payments.sync="payments"
                    :salesOrder="{
                      order_id,
                      order_date,
                      customer,
                      order_value
                    }"
                  />

                  <!-- Deposit and Balance Feature-->
                  <span v-else>
                    <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <h4>Payments</h4>
                    </v-col>
                  </v-row>
                  <!--Deposits-->
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model.number="deposit_recieved"
                        label="Deposit Received"
                        color="#3d2cdd"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <!--Balance-->
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model.number="balance_recieved"
                        label="Balance Received"
                        color="#3d2cdd"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  </span>
            </span>

              <!--Line Items-->
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  lg="11"
                  sm="11"
                  md="11"
                  offset-lg="1"
                  offset-md="1"
                  offset-sm="1"
                >
                  <h3 class="mt-5">Line Items</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="5" md="5" offset-lg="1" offset-md="1" offset-sm="1">
                  <h4>Item</h4>
                </v-col>
                <v-col sm="1" md="1">
                  <h4>Qty</h4>
                </v-col>
                <v-col sm="2" md="2">
                  <h4>Unit Price</h4>
                </v-col>
                <v-col sm="2" md="2">
                  <h4>Amount (R)</h4>
                </v-col>
              </v-row>
              <v-row v-if="!lineItemsLoading">
                <v-col lg="12">
                  <draggable
                    :list="line_items"
                    group="line_items"
                    :options="{ handle: '.dragg-me' }"
                  >
                    <transition-group>
                      <v-form v-for="(item, index) in line_items" :key="index">
                        <v-container
                          @mouseover="hover = true"
                          @mouseleave="hover = false"
                        >
                          <v-row>
                            <!--Delete Button-->
                            <v-col sm="1" md="1">
                              <v-btn
                                class="dragg-me"
                                v-if="hover"
                                icon
                                large
                                color="#141442"
                              >
                                <v-icon>mdi-drag</v-icon>
                              </v-btn>
                            </v-col>
                            <!-- Product dropdown-->
                            <v-col
                              :sm="item.hasVariants ? '3' : '5'"
                              :md="item.hasVariants ? '3' : '5'"
                              >
                              <v-autocomplete
                                v-model="item.product"
                                :items="products"
                                item-text="product_name"
                                return-object
                                placeholder="Choose a product..."
                                :rules="[RequireFormInput('product')]"
                                cache-items
                                required
                                outlined
                                dense
                                color="#3d2cdd"
                                trim
                                @change="setLineItemData(item.product, index)"
                              >
                                <!--Product Selection-->
                                <template v-slot:selection="data" trim>
                                  {{ data.item.product_name }}
                                </template>
                                <!--Dropdown items-->
                                <template v-slot:item="data">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="data.item.product_name"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-html="data.item.product_code"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <!--A button that lets users add new customers if not in select list
                                                        Reroute to Add Customer interface-->
                                <template>
                                  <v-list-item slot="append-item">
                                    <v-btn
                                      text
                                      color="#3d2cdd"
                                      to="/new-product"
                                      ><v-icon>mdi-plus</v-icon> Add New
                                      Product</v-btn
                                    >
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                              <!--end of product dropdown-->
                            </v-col>

                            <!-- Variants -->
                            <v-col v-if="item.hasVariants" sm="2" md="2">
                              <v-autocomplete
                                  v-model="item.variant"
                                  :items="item.variantList"
                                  :rules="[RequireFormInput('variant')]"
                                  :item-text="variant => `${variant.variant_set_name} - ${variant.variant_name}`"
                                  return-object
                                  cache-items
                                  :label="item.variant ? item.variant.variant_set_name : 'Variant'"
                                  outlined
                                  dense
                                  color="#3d2cdd"
                                  class="font-weight-bold"
                                  :menu-props="{ closeOnClick: true }"
                              >
                                <!-- Selected variant display -->
                                <template v-slot:selection="data">
                                    {{ data.item.variant_name }}
                                </template>
                                <!-- Dropdown items -->
                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ `${data.item.variant_set_name} - ${data.item.variant_name}` }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>

                            <v-col sm="1" md="1">
                              <v-text-field
                                v-model.number="item.item_qty"
                                min="0"
                                placeholder="QTY"
                                type="number"
                                outlined
                                dense
                                color="#3d2cdd"
                                @change="sales_order_updated = true"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col sm="2" md="2">
                              <v-text-field
                                v-model.number="item.product_price"
                                prefix="R"
                                min="0"
                                step="0.01"
                                type="number"
                                placeholder="Price"
                                outlined
                                dense
                                color="#3d2cdd"
                              >
                                <template v-slot:append v-if="item.catalogue_price">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                color="primary"
                                                @click="setPriceToCataloguePrice(item, index)"
                                            >
                                              <v-icon>mdi-information</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Click to set the price to the catalogue price</span>
                                    </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                            <!--Line amount total-->
                            <v-col sm="2" md="2">
                              <v-text-field
                                v-model.number="lineItemSubTotal[index]"
                                prefix="R"
                                type="number"
                                outlined
                                readonly
                                dense
                                color="#3d2cdd"
                              >
                              </v-text-field>
                            </v-col>
                            <!--Menu options Button-->
                            <v-col sm="1" md="1">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <!--Menu items-->
                                <v-list>
                                  <!--Add comment-->
                                  <v-list-item
                                    @click="
                                      item.show_comment_field =
                                        !item.show_comment_field
                                    "
                                  >
                                    <v-list-item-title>
                                      <v-icon left small
                                        >mdi-comment-outline</v-icon
                                      >
                                      Add Comment
                                    </v-list-item-title>
                                  </v-list-item>
                                  <!--Delete-->
                                  <v-list-item
                                    @click="removeItem(index)"
                                    :disabled="deleteDisable"
                                  >
                                    <v-list-item-title>
                                      <v-icon left small
                                        >mdi-trash-can-outline</v-icon
                                      >
                                      Delete Item
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <!--Comment field-->
                          <v-row v-show="item.show_comment_field === true">
                            <v-col sm="10" md="10" offset-md="1" offset-sm="1">
                              <v-textarea
                                v-model="item.line_item_comment"
                                counter="250"
                                outlined
                                dense
                                label="Comments"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </transition-group>
                  </draggable>
                  <!--Add more items to order-->
                  <v-row>
                    <v-col offset-lg="1" offset-md="1" offset-sm="1">
                      <v-btn @click="addItems" color="#33cc99" dark large>
                        <v-icon left>mdi-plus</v-icon>
                        Add Item
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Line item loading state -->
              <v-row v-else>
                <v-col class="text-center">
                  <v-progress-circular indeterminate color="#3d2cdd"></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <!--Total breakdown-->
          <v-container class="px-5">
            <v-row>
              <v-col sm="4" md="4" offset-md="8" offset-sm="8">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Subtotal
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ calculateSubtotal | toCurrency }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Discount
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ calculateDiscount() | toCurrency }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Discounted Subtotal
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ calculateDiscountedSubtotal() | toCurrency }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Delivery Fee
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ order_delivery_fee | toCurrency }}
              </v-col>
            </v-row>

            <div v-show="this.company_is_vattable === true">
              <v-row>
                <v-col
                  sm="2"
                  md="2"
                  offset-sm="8"
                  offset-md="8"
                  class="text-right"
                >
                  Total (Ex VAT)
                </v-col>
                <v-col sm="2" md="2" class="text-right">
                  {{ calculateTotalExVat() | toCurrency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  sm="2"
                  md="2"
                  offset-sm="8"
                  offset-md="8"
                  class="text-right"
                >
                  VAT Amount
                </v-col>
                <v-col sm="2" md="2" class="text-right">
                  {{ calculateVATamount() | toCurrency }}
                </v-col>
              </v-row>
            </div>
            <v-row class="my-3">
              <v-col sm="4" md="4" offset-md="8" offset-sm="8">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                <h3>Total</h3>
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                <h3>{{ calculateTotal() | toCurrency }}</h3>
              </v-col>
            </v-row>
            <!--Payments Section-->
            <view-sales-orders-payment-summary
              v-if="order_amount_paid !== 0"
              :customer_payments="customer_payments"
              :amount_paid="order_amount_paid"
              :value="{
                order_id: order_id,
                order_number: order_number,
                order_value: order_value,
                customer_payments: customer_payments,
              }"
            >
            </view-sales-orders-payment-summary>
            <!--ALERT-->
            <SalesOrderInvoicedAlert
              v-if="!!invoice_id"
              :customer="customer"
              :invoice="{
                invoice_number: invoice_number,
                invoice_id: invoice_id,
              }"
            />
          </v-container>
          <v-card-actions class="mt-5" v-if="!invoice_id">
            <v-spacer></v-spacer>
            <!--Delete Sales Order button-->
            <v-btn to="/sales-orders" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="deleteSalesOrder"
              hint
              color="#ce2458"
              text
              x-large
              :loading="loading_delete"
              :disabled="invoice_id"
              v-if="this.user_roles_list.flat().includes('sales_delete')"
            >
              Delete
              <v-icon right>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              @click="updateSalesOrder"
              color="#33cc99"
              text
              x-large
              :disabled="!valid || !!invoice_id"
              :loading="loading"
            >
              Update
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
          <!--Display button so user can close off invoiced sales orders-->
          <v-card-actions
            class="mt-5"
            v-if="invoice_id && order_status !== 'Completed'"
          >
            <v-spacer></v-spacer>
            <v-btn to="/sales-orders" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="markInvocedOrderAsCompleted()"
              :loading="loading"
              ><v-icon left>mdi-check</v-icon>Set Order as "Completed"</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import mixinCompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import mixin_InitSalesOrder from "../mixins/mixin_InitSalesOrder";
import mixin_GetCustomersProducts from "../mixins/mixin_GetCustomersProducts";
import { showSnackbar } from "../../../globalActions/index";
import { generateSalesPDF, setVATRate } from "../data/external_sales_orders";
import draggable from "vuedraggable";
// Feature flags
import { featureFlags } from "../../../composables/featureFlags.js";

export default {
  name: "EditSalesOrder",
  components: {
    draggable,
    ViewSalesOrdersPaymentSummary: () =>
      import("../components/ViewSalesOrdersPaymentSummary"),
    QuoteNumberInput: () => import("../components/Inputs/QuoteNumberInput.vue"),
    OrderNumberInput: () => import("../components/Inputs/OrderNumberInput.vue"),
    SalesOrderInvoicedAlert: () =>
      import("../components/Alerts/SalesOrderInvoicedAlert.vue"),
    PaymentList: () => import("../components/PaymentList.vue"),
  },
  mixins: [
    mixin_UserRoles,
    mixinCompanyProfile,
    mixin_InitSalesOrder,
    mixin_GetCustomersProducts,
  ],

  data() {
    return {
      //New jobs are set as 'Quotes' by default
      order_id: null,
      order_date: null,
      order_branch: null,
      branches: [],
      customer: null,
      order_status: null,
      order_value: 0,
      deposit_recieved: null,
      balance_recieved: null,
      work_order_production_status: null,
      work_order_id: null,
      delivery_status: null,
      delivery_id: null,
      lineItemsLoading: true,
      line_items: [
        {
          product_id: null,
          product_name: "",
          item_qty: 0,
          qty_completed: 0,
          product_price: 0,
          line_item_amount: 0,
          show_comment_field: false,
          line_item_comment: "",
        },
      ],
      discountRate: 0,
      order_discount: 0,
      order_discounted_subtotal: 0,
      order_delivery_fee: 0,
      order_subtotal: 0,
      order_total_ex_VAT: 0,
      VAT_rate: 0,
      order_VAT_amount: 0,
      offset: true,
      //Deleting line items
      deleteDisable: false,
      //Only increment order counter if the job status has changed from quote
      trigger_order_counter: false,
      loading: false,
      loading_customer: false,
      loading_product: false,
      loading_delete: false,
      order_amount_paid: null,
      order_amount_due: null,
      quote_ref: null,
      invoice_id: null,
      invoice_number: null,
      customer_payments: null,

      payments: [],

      //Defines rules for form validation --> passes properties and rules bound to input fields
      // Valid property has a v-bind to the submit button and toggles disabled state
      valid: false,

      // FEATURE FLAGS
      featureFlags,

      RequireFormInput(propertyType) {
        return (v) =>
          (v && v.length !== null) || `You must specify a ${propertyType}`;
      },
      minLength(propertyType, minLength) {
        return (v) =>
          (v && v.length >= minLength) ||
          `The ${propertyType} must be at least ${minLength} characters long`;
      },
      maxLength(propertyType, maxLength) {
        return (v) =>
          v.length <= maxLength ||
          `The ${propertyType} can't be more than ${maxLength} characters long`;
      },
    };
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("sales_orders")
      .where("order_id", "==", to.params.order_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.order_id = doc.data().order_id;
            vm.order_branch = doc.data().order_branch;
            vm.order_number = doc.data().order_number;
            vm.order_date = doc.data().order_date;
            vm.customer = doc.data().customer;
            vm.order_status = doc.data().order_status;
            vm.order_value = doc.data().order_value;
            vm.discountRate = doc.data().discountRate;
            vm.order_delivery_fee = doc.data().order_delivery_fee;
            vm.deposit_recieved = doc.data().deposit_recieved;
            vm.balance_recieved = doc.data().balance_recieved;
            vm.work_order_production_status =
              doc.data().work_order_production_status;
            vm.work_order_id = doc.data().work_order_id;
            vm.sales_order_updated = doc.data().sales_order_updated;
            vm.sales_order_cancelled = doc.data().sales_order_cancelled;
            vm.delivery_status = doc.data().delivery_status;
            vm.delivery_id = doc.data().delivery_id;
            vm.line_items = doc.data().line_items;
            vm.VAT_rate = doc.data().VAT_rate;
            vm.order_VAT_amount = doc.data().order_VAT_amount;
            vm.order_total_ex_VAT = doc.data().order_total_ex_VAT;
            vm.order_amount_paid = doc.data().order_amount_paid;
            vm.order_amount_due = doc.data().order_amount_due;
            vm.quote_ref = doc.data().quote_ref;
            vm.invoice_id = doc.data().invoice_id;
            vm.invoice_number = doc.data().invoice_number;
            vm.customer_payments = doc.data().customer_payments;
            vm.payments = doc.data().payments || [];
          });
        });
      });
  },
  created() {
    this.setVATRate();

    //Get Order counter from database
    db.collection("order_number_counter")
      .doc("VVr0gV8WMDdzHNwkKQSe")
      .onSnapshot((snapshot) => {
        // Retreive counter object from datatbase
        const document = snapshot.data();
        //2) Destructure object to get individual counter values
        const { order_count } = document;
        //Assign counter values as variables
        this.order_count = "SO-".concat(order_count.toString());
        console.log(order_count);
    });

    // Get branch list from database
    this.getBranches();
  },
  mounted() {
    //Disable status dropdown options depending on current status
    if (this.order_status != "Quote") {
      this.order_status_items[0].disabled = true;
    }
    // Initialise edit mode line item data
    this.initLineItemData(this.line_items);
  },
  updated() {
    //Disable status dropdown options depending on current status
    if (this.order_status != "Quote") {
      this.order_status_items[0].disabled = true;
    }
    if (this.order_status === "Cancelled") {
      this.sales_order_cancelled = true;
    } else {
      this.sales_order_cancelled = false;
    }
  },
  watch: {
    $route: "fetchData",
  },
  computed: {
    //Total quantity of all items on order
    totalQty() {
      return this.line_items.reduce((total, item) => {
        return total + Number(item.item_qty);
      }, 0);
    },
    //Calculates line item total amount and assigns to variable to save to database
    lineItemSubTotal() {
      return this.line_items.map((item) => {
        return (item.line_item_amount = Number(
          item.item_qty * item.product_price
        ).toFixed(2));
      });
    },
    calculateSubtotal() {
      return this.line_items.reduce((total, item) => {
        return (this.order_subtotal =
          total + item.item_qty * item.product_price);
      }, 0);
    },
    //Determine PDF title based on order status
    proFormaTitle() {
      return this.order_status === "Quote" ? "Quotation" : "Sales Order";
    },
    //Show 'Cancelled' stamp on job documents
    cancelStamp() {
      return this.order_status === "Cancelled" ? "CANCELLED" : "";
    },
    //Show 'Cancelled' stamp on job documents
    completedStamp() {
      return this.order_status === "Completed" ? "COMPLETED" : "";
    },
  },
  methods: {
    setVATRate,
    generateSalesPDF,
    calculateDiscount() {
      // Calculate discount value as a number, ensuring the result is still a float
      this.order_discount = parseFloat((this.order_subtotal * (this.discountRate / 100)).toFixed(2));
      return this.order_discount;
    },
    calculateDiscountedSubtotal() {
      return (this.order_discounted_subtotal =
        this.order_subtotal - this.order_discount);
    },
    calculateTotalExVat() {
      const amount = this.order_discounted_subtotal + this.order_delivery_fee;
      return (this.order_total_ex_VAT = amount / (1 + this.VAT_rate));
    },
    calculateTotal() {
      return (this.order_value =
        this.order_discounted_subtotal + this.order_delivery_fee);
    },
    calculateVATamount() {
      return (this.order_VAT_amount =
        this.order_value - this.order_total_ex_VAT);
    },
    updateAmountDue() {
      return this.order_value - this.order_amount_paid;
    },
    //Maps database data to template relative to the sales order selected in this route
    fetchData() {
      db.collection("sales_orders")
        .where("order_id", "==", this.$route.params.order_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            this.order_id = doc.data().order_id;
            this.order_branch = doc.data().order_branch;
            this.order_number = doc.data().order_number;
            this.order_date = doc.data().order_date;
            this.customer = doc.data().customer;
            this.order_status = doc.data().order_status;
            this.order_value = doc.data().order_value;
            this.discountRate = doc.data().discountRate;
            this.order_delivery_fee = doc.data().order_delivery_fee;
            this.deposit_recieved = doc.data().deposit_recieved;
            this.balance_recieved = doc.data().balance_recieved;
            this.work_order_production_status =
              doc.data().work_order_production_status;
            this.work_order_id = doc.data().work_order_id;
            this.delivery_status = doc.data().delivery_status;
            this.delivery_id = doc.data().delivery_id;
            this.sales_order_updated = doc.data().sales_order_updated;
            this.sales_order_cancelled = doc.data().sales_order_cancelled;
            this.VAT_rate = doc.data().VAT_rate;
            this.order_subtotal = doc.data().order_subtotal;
            this.order_VAT_amount = doc.data().order_VAT_amount;
            this.order_total_ex_VAT = doc.data().order_total_ex_VAT;
            this.order_amount_paid = doc.data().order_amount_paid;
            this.order_amount_due = doc.data().order_amount_due;
            this.quote_ref = doc.data().quote_ref;
            this.invoice_id = doc.data().invoice_id;
            this.invoice_number = doc.data().invoice_number;
            this.customer_payments = doc.data().customer_payments;
            this.payments = doc.data().payments;
            this.line_items = doc.data().line_items.map(item => ({
          ...item,
          assigned_to_plan: item.assigned_to_plan || null
        }));
          });
        });
    },
    //Add new line item to sales order
    addItems() {
      this.line_items.push({
        product_id: null,
        product_name: "",
        item_qty: 0,
        qty_completed: 0,
        product_price: 0,
        line_item_amount: 0,
        show_comment_field: false,
        line_item_comment: "",
      });
      this.deleteDisable = false;
    },
    //Deletes a specific line item from sales order
    removeItem: function (index) {
      this.line_items.splice(index, 1);
      if (this.line_items.length === 1) {
        this.deleteDisable = true;
      }
    },
    //Change order number when job status is no longer 'Quote'
    updateOrderNum() {
      if (
        this.order_status === "Confirmed" ||
        this.order_number === "Completed"
      ) {
        this.order_number = this.order_count;
        this.trigger_order_counter = true;
      } else if (this.order_status === "Cancelled") {
        this.trigger_order_counter = false;
      }
    },
    //_______________________________________________________________
    //DELETE SALES ORDER
    //Check if the sales order has a work order assigned to item
    checkWorkOrder() {
      if (this.work_order_production_status != "Not Started") {
        //Informs the WORK ORDER that the sales order has been cancelled
        db.collection("work_orders")
          .where("work_order_id", "==", this.work_order_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.update({
                sales_order_cancelled: true,
              });
            });
          });
      }
    },
    //Check if the sales order has a work order assigned to item
    checkDeliveryNote() {
      if (this.delivery_status != "Not Started") {
        //Informs the DELIVERY NOTE that the sales order has been deleted
        db.collection("delivery_notes")
          .where("delivery_id", "==", this.delivery_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.update({
                sales_order_cancelled: true,
              });
            });
          });
      }
    },
    //Removes sales order from database
    deleteSalesOrder() {
      if (confirm("Are you sure you want to delete this sales order?")) {
        this.loading_delete = true;
        //1) Check if the job has a WORK ORDER
        this.checkWorkOrder();

        //2) Checks if the job has a DELIVERY NOTE
        this.checkDeliveryNote();

        //3) Remove this sales order from the firebase collection
        db.collection("sales_orders")
          .where("order_id", "==", this.$route.params.order_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
              this.loading_delete = false;
              //Notify user that sales order has been deleted
              showSnackbar("Sales Order deleted successfully");
              //Reroute to sales orders list after database is updated
              this.$router.push("/sales-orders");
            });
          });
      }
    },
    //_______________________________________________________________
    //UPDATE SALES ORDER
    updateWorkOrderStatus() {
      //  Passes cancelled / updated status to WORK ORDER
      //  Passes updated line items to WORK ORDER
      if (this.work_order_production_status != "Not Started") {
        db.collection("work_orders")
          .where("work_order_id", "==", this.work_order_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.update({
                sales_order_number: this.order_number,
                sales_order_cancelled: this.sales_order_cancelled,
                sales_order_updated: this.sales_order_updated,
                updated_line_items: this.line_items,
                work_order_production_status: this.work_order_production_status,
              });
            });
          });
      }
      //  If a job is cancelled with a completed work order, and the job
      //  is then uncancelled, need to reset the production status in case you
      //  need to make a new works order
      if (
        this.work_order_production_status === "Completed" &&
        this.order_status === "Cancelled"
      ) {
        this.work_order_production_status = "Not Started";
      }
    },
    updateDeliveryNoteStatus() {
      //  2)Passes cancelled / updated status to DELIVERY NOTE
      //  Passes updated line items to DELIVERY NOTE
      if (this.delivery_status != "Not Started") {
        db.collection("delivery_notes")
          .where("delivery_id", "==", this.delivery_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.update({
                sales_order_number: this.order_number,
                sales_order_cancelled: this.sales_order_cancelled,
                sales_order_updated: this.sales_order_updated,
                updated_line_items: this.line_items,
                delivery_status: this.delivery_status,
              });
            });
          });
      }
      //  If a job is cancelled with a completed delivery note, and the job
      //  is then uncancelled, need to reset the delivery status in case you
      //  need to make a new delivery note
      if (
        this.delivery_status === "Completed" &&
        this.order_status === "Cancelled"
      ) {
        this.delivery_status = "Not Started";
      }
    },

    updateSalesOrder() {
      //Form validation --> ensures required fields aren't blank before proceeding further
      if (this.$refs.form.validate()) {
        this.loading = true;
        //  1)Passes cancelled / updated status to WORK ORDER
        this.updateWorkOrderStatus();
        //  2)Passes cancelled / updated status to DELIVERY NOTE
        this.updateDeliveryNoteStatus();

        // 3)Updates the sales order in firebase collection
        db.collection("sales_orders")
          .where("order_id", "==", this.$route.params.order_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref
                .update({
                  order_number: this.order_number,
                  order_branch: this.order_branch,
                  order_date: this.order_date,
                  customer: this.customer,
                  order_status: this.order_status,
                  order_value: this.order_value,
                  order_subtotal: this.order_subtotal,
                  discountRate: this.discountRate,
                  order_discount: this.order_discount,
                  order_discounted_subtotal: this.order_discounted_subtotal,
                  order_delivery_fee: this.order_delivery_fee,
                  VAT_rate: this.VAT_rate,
                  order_VAT_amount: this.order_VAT_amount,
                  deposit_recieved: this.deposit_recieved,
                  balance_recieved: this.balance_recieved,
                  work_order_production_status:
                    this.work_order_production_status,
                  delivery_status: this.delivery_status,
                  line_items: this.formatLineItemsForSubmission(this.line_items),
                  sales_order_cancelled: this.sales_order_cancelled,
                  sales_order_updated: this.sales_order_updated,
                  order_total_ex_VAT: this.order_total_ex_VAT,
                  quote_ref: this.quote_ref ? this.quote_ref : null,
                  invoice_id: this.invoice_id ? this.invoice_id : null,
                  order_amount_due: this.updateAmountDue(),
                  payments: this.payments,
                })
                //Reroute to sales order view page after database has been updated
                .then(() => {
                  this.loading = false;
                  if (this.trigger_order_counter === true) {
                    const db = firebase.firestore();
                    //Order number counter
                    //A counter in a doc in firebase is incremented when a new order is generated
                    //The latest number is then used as the sales order number
                    const increment =
                      firebase.firestore.FieldValue.increment(1);
                    const orderNumberCount = db
                      .collection("order_number_counter")
                      .doc("VVr0gV8WMDdzHNwkKQSe");
                    orderNumberCount.update({ order_count: increment });

                    showSnackbar("Sales Order updated successfully");
                    this.$router.push("/sales-orders");
                  } else {
                    showSnackbar("Sales Order updated successfully");
                    this.$router.push("/sales-orders");
                  }
                });
            });
          });
      }
    },
    //
    markInvocedOrderAsCompleted() {
      this.loading = true;
      const docRef = db.doc(`sales_orders/${this.order_id}`);
      docRef
        .update({
          order_status: "Completed",
        })
        .then(() => {
          this.loading = false;
          showSnackbar("Sales Order has been set as 'Completed");
          this.$router.push("/sales-orders");
        })
        .catch((error) => error);
    },

    // Get branch list from database
    async getBranches() {
      const docRef = db.collection("branches").orderBy("branch_name");

      docRef.onSnapshot((snapshot) => {
          this.branches = [];
          this.branches = snapshot.docs.map(doc => doc.data());
      });
    },
    // Set line item defaul data
    async setLineItemData(product, index) {
      // Check if there was a previous product_price set
      const previousPrice = this.line_items[index].product_price;
      // Reset variant data
      this.$set(this.line_items[index], 'hasVariants', false);
      this.$set(this.line_items[index], 'variant', null);
      
      const { product_id, product_price, product_name, product_variants } = product;

      // Set product data
      this.$set(this.line_items[index], 'product_id', product_id);
      this.$set(this.line_items[index], 'product_name', product_name);
      this.$set(this.line_items[index], 'product_price', previousPrice ? previousPrice : product_price || 0);

      if (previousPrice !== product_price && previousPrice !== 0) {
        // Save the catalogue price so user can switch between prices if need be
        this.$set(this.line_items[index], 'catalogue_price', product_price);
      }

      // Check for variants
      if (product_variants.length === 0) return;

      this.$set(this.line_items[index], 'hasVariants', true);
      const variants = await this.getVariants(product_variants);

      this.$set(this.line_items[index], 'variantList', variants);
    },
    //
    setPriceToCataloguePrice(item, index){
      this.$set(this.line_items[index], 'product_price', item.catalogue_price);
      delete item.catalogue_price;
    },
    // Get variants from database
    async getVariants(product_variants) {
      // Extract variant_set_id from each product_variant
      const variantSetIds = product_variants.map(variant => variant.variant_set_id);

      // Get all variant_sets that match the ids in variantSetIds
      const snapshot = await db.collection("variant_sets")
          .where("variant_set_id", "in", variantSetIds)
          .get();

      // Reduce the query results to a single flat array of variants
      const variants = snapshot.docs.reduce((acc, doc) => {
          const variantSet = doc.data();
          // Add variant_set_name to each variant
          const variantsWithSetName = variantSet.variants.map(variant => ({
              ...variant,
              variant_set_name: variantSet.variant_set_name
          }));
          return acc.concat(variantsWithSetName);
      }, []);

      return variants;
    },
    formatLineItemsForSubmission(lineItems) {
      return lineItems.map(e => ({
        item_qty: e.item_qty,
        line_item_amount: e.line_item_amount,
        line_item_comment: e.line_item_comment,
        product_id: e.product_id,
        product_name: e.product_name,
        product_price: e.product_price,
        show_comment_field: e.show_comment_field,
        variant: e.variant,
        qty_completed: e.qty_completed,
        assigned_to_plan: e.assigned_to_plan || null,
      }))
    },
    // Set the edit mode data for all line items
    async initLineItemData(lineItems) {
      if (!lineItems || lineItems.length === 0) {
        this.lineItemsLoading = false;
        return;
      }

      // Filter out line items without a product_id
      const validLineItems = lineItems.filter(item => item.product_id);

      if (validLineItems.length === 0) {
        this.lineItemsLoading = false;
        return;
      }

      const productIds = validLineItems.map(item => item.product_id);

      // Break productIds into chunks of 10
      const chunks = [];
      for (let i = 0; i < productIds.length; i += 10) {
        chunks.push(productIds.slice(i, i + 10));
      }

      // Fetch products in chunks and initialize line items
      for (const chunk of chunks) {
        const snapshot = await db.collection("products")
          .where("product_id", "in", chunk)
          .get();

        snapshot.docs.forEach(async (doc) => {
          const productData = doc.data();
          // Find the index of the line item corresponding to this product
          const index = lineItems.findIndex(item => item.product_id === productData.product_id);

          // Preserve the assigned_to_plan field
          const assignedToPlan = this.line_items[index].assigned_to_plan;

          // Set the entire product object
          this.$set(this.line_items[index], 'product', productData);
          this.$set(this.line_items[index], 'assigned_to_plan', assignedToPlan);

          // Check for variants and populate variant selection list
          if (productData.product_variants && productData.product_variants.length > 0) {
            this.$set(this.line_items[index], 'hasVariants', true);
            const variants = await this.getVariants(productData.product_variants);
            this.$set(this.line_items[index], 'variantList', variants);
          }
        });
      }

      // All chunks processed, set loading to false
      this.lineItemsLoading = false;
    }

  },
};
</script>